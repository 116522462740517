import { useState, useCallback } from 'react';

const useBooleanState = (init: boolean): [boolean, () => void, () => void] => {
  const [state, setState] = useState(init);

  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);

  return [state, setTrue, setFalse];
};

export default useBooleanState;
