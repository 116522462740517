/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';

// https://www.30secondsofcode.org/react/s/use-media-query
const useMediaQuery = (query: string): boolean => {
  if (typeof window === 'undefined' || typeof window.matchMedia === 'undefined') return false;

  const mediaQuery = window.matchMedia(query);
  const [match, setMatch] = React.useState(!!mediaQuery.matches);

  React.useEffect(() => {
    const handler = () => setMatch(!!mediaQuery.matches);
    mediaQuery.addListener(handler);
    return () => mediaQuery.removeListener(handler);
  }, [mediaQuery]);

  return match;
};

export default useMediaQuery;

export const useMobile = (): boolean => useMediaQuery('screen and (max-width: 481px)');
export const useTablet = (): boolean => useMediaQuery('screen and (max-width: 961px)');
