import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBars } from '@fortawesome/free-solid-svg-icons';
import styled, { keyframes } from 'styled-components';

import { rhythm } from '../utils/typography';
import { useMobile } from '../utils/useMediaQuery';
import useBooleanState from '../utils/useBooleanState';
import { NavBarQuery } from '../../types/graphql-types';

interface NavBarItemProps {
  path: string;
  name: string;
}

const NavBarItem: React.FC<NavBarItemProps> = ({ path, name }) => <Link to={path}>{name}</Link>;

const NavBarItems: React.ReactNodeArray = [
  <NavBarItem path="/" name="Home" key="nav-home" />,
  <NavBarItem path="/blog" name="Blog" key="nav-blog" />,
  <NavBarItem path="/work" name="Work" key="nav-work" />,
  <NavBarItem path="/contact" name="Contact" key="nav-contact" />,
];

const NavBarContainer = styled.div`
  background-color: rgb(51, 51, 51);
  color: white;
`;

const MobileNavBarItemsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: ${rhythm(1 / 3)};
  & > * {
    margin: ${rhythm(1 / 5)};
  }
`;

const DesktopNavBarItemsContainer = styled.div`
  & > * {
    margin: 0 ${rhythm(1 / 10)};
  }
`;

const DesktopContainer = styled.div`
  display: flex;
  flex: 0;
  justify-content: space-between;
  align-items: center;
  padding: ${rhythm(1 / 3)};
  flex-direction: row;
`;

const PopupAppearAnimation = keyframes`
  0% { width: 0vw; opacity: 0; }
  100% { width: 100vw; opacity: 1; }
`;

const Popup = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 0vw;
  min-height: 100vh;
  z-index: 100;
  overflow: hidden;
  animation: ${PopupAppearAnimation} 300ms ease both;
  background-color: rgba(51, 51, 51, 0.8);
  color: white;
`;

const CloseIconContainer = styled.div`
  padding: ${rhythm(1 / 3)};
`;

const SiteTitleContainer = styled(Link)`
  padding-left: ${rhythm(1)};
  font-weight: 700;
  text-decoration: none;
`;

const NavBar: React.FC = () => {
  const isMobile = useMobile();
  const [isOpen, setOpen, setClose] = useBooleanState(false);

  const { site } = useStaticQuery<NavBarQuery>(navBarQuery);

  return (
    <NavBarContainer>
      {isMobile && isOpen && (
        <Popup>
          <CloseIconContainer>
            <FontAwesomeIcon icon={faArrowRight} onClick={setClose} />
          </CloseIconContainer>
          <MobileNavBarItemsContainer>{NavBarItems}</MobileNavBarItemsContainer>
        </Popup>
      )}
      <DesktopContainer>
        <SiteTitleContainer to="/">{site.siteMetadata.title}</SiteTitleContainer>
        {isMobile ? (
          <FontAwesomeIcon icon={faBars} onClick={setOpen} />
        ) : (
          <DesktopNavBarItemsContainer>{NavBarItems}</DesktopNavBarItemsContainer>
        )}
      </DesktopContainer>
    </NavBarContainer>
  );
};

export default NavBar;

const navBarQuery = graphql`
  query NavBar {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
