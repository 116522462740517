import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  flex: 0;
  margin: 1em 0;
  width: 100%;
  text-align: center;
  font-size: 0.5em;
`;
const Footer: React.FC = () => <FooterContainer>© Copyright 2020 Shun Nishimura - All Rights Reserved</FooterContainer>;

export default Footer;
