/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { SeoQuery } from '../../types/graphql-types';

interface SEOProps {
  description?: string;
  lang?: string;
  meta?: Record<string, unknown>[];
  title?: string;
  image?: string;
}

const SEO: React.FC<SEOProps> = (props) => {
  const { pathname } = useLocation();
  const {
    site: { siteMetadata },
  } = useStaticQuery<SeoQuery>(seoQuery);

  const url = `${siteMetadata.siteUrl}${pathname}`;
  const title = props.title || siteMetadata.title;
  const lang = props.lang || 'ja';
  const metaDescription = props.description || siteMetadata.description;
  const image = `${siteMetadata.siteUrl}/${props.image || 'site-top.png'}`;
  const meta = props.meta || [];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:url',
          content: url,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:site',
          content: siteMetadata.social.twitter,
        },
        {
          name: 'twitter:creator',
          content: siteMetadata.social.twitter,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: image,
        },
        ...meta,
      ]}
    />
  );
};

export default SEO;

const seoQuery = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
        title
        description
        social {
          twitter
        }
      }
    }
  }
`;
