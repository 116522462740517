import React from 'react';
import styled from 'styled-components';

import Footer from '../components/footer';
import NavBar from '../components/nav-bar';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('smooth-scroll')('a[href*="#"]');
}

const BaseContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  padding: ${rhythm(1 / 2)} ${rhythm(1)};
  flex: 1;
`;

interface BaseLayoutProps {
  title?: string;
  description?: string;
  meta?: Record<string, unknown>[];
}

const BaseLayout: React.FC<BaseLayoutProps> = ({ children, description, title, meta }) => (
  <BaseContainer>
    <SEO title={title} description={description} meta={meta} />
    <NavBar />
    <ContentContainer>{children}</ContentContainer>
    <Footer />
  </BaseContainer>
);

export default BaseLayout;
